import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import LinearProgress from "../components/LinearProgress";

import {
  DashboardRouteComponent,
  DefaultRouteComponent,
  SignInRouteComponent,
  ProfileRouteComponent,
  QuestionsRouteComponent,
  signInRoute,
  dashboardRoute,
  profileRoute,
  questionsRoute,
} from "./config";
import { useGlobalLoading } from "../hooks/GlobalLoadingContext";
import { useAuth } from "../hooks/AuthContext";
import { RequestProvider } from "../hooks/RequestContext";
import { AnalyticsProvider, useAnalytics } from "../hooks/AnalyticsContext";

import { Main } from "./styles";

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={signInRoute.path} component={SignInRouteComponent} exact />
      <Route path={dashboardRoute.path} exact>
        <DashboardRouteComponent />
      </Route>
      <Route path={profileRoute.path} exact>
        <ProfileRouteComponent />
      </Route>
      <Route path={questionsRoute.path} exact>
        <QuestionsRouteComponent />
      </Route>

      <Route>
        <DefaultRouteComponent />
      </Route>
    </Switch>
  );
};

const PrivateRoutes: React.FC<{ loading: boolean }> = ({ loading }) => {
  const { dispatchAction } = useAnalytics();

  useEffect(() => {
    dispatchAction({ action: 'OPEN_APP' });
  }, [dispatchAction]);

  return (
    <>
      <LinearProgress loading={loading} />
      <Main>
        <AppRoutes />
      </Main>
    </>
  )
}

const Routes: React.FC = () => {
  const { signed } = useAuth();
  const { loading } = useGlobalLoading();

  if (!signed) {
    return (
      <>
        <LinearProgress loading={loading} />
        <AppRoutes />
      </>
    );
  }

  return (
    <RequestProvider>
      <AnalyticsProvider>
        <PrivateRoutes loading={loading} />
      </AnalyticsProvider>
    </RequestProvider>
  );
};

export default Routes;
