import React, { useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { MdArrowDropDown } from 'react-icons/md';

import { dashboardRoute, profileRoute } from '../../routes/config';
import { useAuth } from '../../hooks/AuthContext';

import logoImg from '../../assets/images/multisom.png';

import { Container } from './styles';

const Header: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const handleClick = useCallback(
    () => {
      history.push(dashboardRoute.path);
    },
    [history]
  );

  const formattedName = useCallback(
    () => {
      if (!user.name) return "";

      const firstLetter = user.name.charAt(0);

      return firstLetter.toUpperCase();
    },
    [user]
  );

  return (
    <Container>
      <img
        src={logoImg}
        alt="Logo da Multisom"
        onClick={handleClick}
      />

      <div className="profile">
        <Link to={profileRoute.path}>
          <div>{formattedName()}</div>
          <MdArrowDropDown size={20} />
        </Link>
      </div>
    </Container>
  );
}

export default Header;
