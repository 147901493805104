import React, { useEffect, useCallback, useRef } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { FiUser, FiLock } from "react-icons/fi";
import * as Yup from "yup";
import { toast } from "react-toastify";

import InputText from "../../components/Input/Text";
import Button from "../../components/Button";
import getValidationsErrors from "../../utils/errors/getValidationsErrors";

import logoImage from "../../assets/images/multisom.png";

import { Container } from "./styles";
import { useGlobalLoading } from "../../hooks/GlobalLoadingContext";
import { useAuth } from "../../hooks/AuthContext";

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { loading, setLoading } = useGlobalLoading();
  const { signIn } = useAuth();
  const currentYear = new Date().getFullYear();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string().required("Usuário obrigatório"),
          password: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, { abortEarly: false });
        await signIn({
          ...data,
          username: data.username?.trim()?.toLowerCase(),
        });
      } catch (err) {
        const validationErrors = getValidationsErrors(err);
        formRef.current?.setErrors(validationErrors);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, signIn]
  );

  useEffect(() => {
    const sessionExpired = localStorage.getItem("@App:error:session-expired");

    if (sessionExpired) {
      localStorage.clear();
      toast.error("Sua sessão encerrou. Faça login para continuar navegando!");
    }
  }, []);

  return (
    <Container className="container">
      <Form ref={formRef} onSubmit={handleSubmit} className="login">
        <div className="card-header">
          <img src={logoImage} alt="Logomarca Shcumann" />
        </div>
        <div className="card-body">
          <InputText
            name="username"
            placeholder="Informe seu usuário"
            startAdorment={() => <FiUser size={19} />}
          />
          <InputText
            name="password"
            placeholder="Informe sua senha"
            type="password"
            startAdorment={() => <FiLock size={19} />}
          />
          <Button type="submit" name="Entrar" disabled={loading} />
        </div>
      </Form>

      <footer>
        <span>Copyright © Multisom {currentYear} All Rights Reserved.</span>
      </footer>
    </Container>
  );
};

export default SignIn;
