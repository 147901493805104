import React, { useState, createContext, useContext, useCallback } from "react";
import { toast } from "react-toastify";

import Request from "../services/request";
import { analyticsRouteApi } from "../routes/config/api";

interface IUser {
  id: string;
  name: string;
  username: string;
  is_admin: boolean;
  branch_id: number;
  deleted_at: string | null,
  created_at: string;
  updated_at: string;
  branch: {
    id: number;
    name: string;
    code: number;
    company_id: number;
    created_at: string;
    updated_at: string;
    company: {
      id: number;
      name: string;
      code: number;
      created_at: string;
      updated_at: string;
    }
  }
}

interface IAuth {
  signed: boolean;
  token: string | null;
  user: IUser;
}

interface ISignInProps {
  username: string;
  password: string;
}

export interface IAuthContextProps {
  signed: boolean;
  token: string | null;
  user: IUser;
  signIn(props: ISignInProps): Promise<void>;
  signOut(): void;
  setUser(user: IUser): void;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<IAuth>(() => {
    const token = localStorage.getItem("@App:auth:token");
    const user = localStorage.getItem("@App:auth:user");
    const formattedUser = (user ? JSON.parse(user) : {}) as IUser;

    Request.setHeader("Authorization", `Bearer ${token}`);

    return {
      signed: !!token,
      token: token,
      user: formattedUser,
    };
  });

  const signIn = useCallback(async (data: ISignInProps) => {
    try {
      const response = await Request.post("auth", data);

      localStorage.setItem("@App:auth:token", response.token);
      localStorage.setItem("@App:auth:user", JSON.stringify(response.user));

      Request.setHeader("Authorization", `Bearer ${response.token}`);

      Request.post(analyticsRouteApi.path, { action: 'LOG_IN' });

      setAuth((oldAuth) => ({
        ...oldAuth,
        ...response,
        signed: !!response.token,
      }));

      toast.success("Usuário logado com sucesso!");
    } catch (err) {
      toast.error("Usuário ou senha incorretos");
    }
  }, []);

  const signOut = useCallback(() => {
    Request.post(analyticsRouteApi.path, { action: 'LOG_OUT' });

    localStorage.clear();

    setAuth({
      signed: false,
      token: null,
      user: {} as IUser,
    });
  }, []);

  const setUser = useCallback((user) => {
    localStorage.setItem("@App:auth:user", JSON.stringify(user));

    setAuth((oldData) => ({
      ...oldData,
      user,
    }));
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, signIn, signOut, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};

export default AuthContext;
