import styled from 'styled-components';
import { shade } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  .logout {
    height: 48px;
    border: none;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.red};
    border-radius: 4px;
    margin-top: 32px;
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 250ms ease-in-out;

    &:hover {
      background-color: ${(props) => shade(0.1, props.theme.colors.red)};
    }
  }

  @media(max-width: 800px) {
    padding: 16px;
  }
`;

export const InputShow = styled.div`
  margin-bottom: 8px;

  strong {
    font-size: 13px;
    margin-left: 8px;
  }

  div {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(29, 37, 59, 0.2);
    background-color: ${props => props.theme.colors.white};
    padding: 0 16px;
    font-size: 0.75rem;
    margin-top: 2px;
  }
`;
