import React, { createContext, useContext, useCallback } from "react";

import { useRequest } from "./RequestContext";
import { analyticsRouteApi } from "../routes/config/api";

type IAction = 'OPEN_APP' | 'BUTTON_CLICK' | 'LOG_IN' | 'LOG_OUT';

interface IDispatchActionProps {
  action: IAction;
  description?: string;
}

export interface IAnalyticsContextProps {
  dispatchAction(data: IDispatchActionProps): void;
}

const AnalyticsContext = createContext<IAnalyticsContextProps>({} as IAnalyticsContextProps);

export const AnalyticsProvider: React.FC = ({ children }) => {
  const { post: requestPost } = useRequest();

  const dispatchAction = useCallback(async (data: IDispatchActionProps) => {
    try {
      requestPost(analyticsRouteApi.path, data);
    } catch {}
  }, [requestPost]);

  return (
    <AnalyticsContext.Provider value={{ dispatchAction }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): IAnalyticsContextProps => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }

  return context;
};

export default AnalyticsContext;
