import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 32px 0;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media(max-width: 800px) {
    padding: 24px 16px;
  }
`;

export const Error = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;

  span {
    font-size: 1rem;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    margin-top: 24px;

    &:hover {
      text-decoration: underline;
    }
  }
`;
