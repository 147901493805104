import React, { useRef, useState, useCallback, useEffect } from "react";
import Select, {
  OptionTypeBase,
  Props as SelectProps,
  OptionsType,
  GroupedOptionsType,
} from "react-select";
import { useField } from "@unform/core";
import { shade } from "polished";

import { getTheme } from "../../../styles/theme";

import { Container } from './styles';

export type ISelectOption =
  | GroupedOptionsType<OptionTypeBase>
  | OptionsType<OptionTypeBase>;

export interface InputSelectProps extends SelectProps<OptionTypeBase> {
  name: string;
  label: string;
  helperText?: string;
  options: ISelectOption[];
  onSelect?: (option: ISelectOption) => void;
}

const appTheme = getTheme();

const InputSelect: React.FC<InputSelectProps> = ({
  name,
  label,
  options,
  onSelect,
  helperText,
  ...rest
}) => {
  const [state, setState] = useState<ISelectOption>([]);
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleChange = useCallback(
    (newState: any) => {
      setState(newState);
      onSelect && onSelect(newState);
    },
    [onSelect]
  );

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: selectRef.current,
        getValue: (ref: any) => {
          return state;
        },
        setValue: (ref: any, value: any) => {
          setState(value);
        },
      });
    }
  }, [fieldName, registerField, rest.isMulti, state]);

  useEffect(() => {
    setState(defaultValue);
  }, [defaultValue]);

  return (
    <Container>
      <Select
        isClearable
        {...rest}
        ref={selectRef}
        name={name}
        defaultValue={defaultValue}
        classNamePrefix="react-select"
        styles={{
          input: () => ({
            fontSize: "0.75rem",
            color: "#777",
          }),
          singleValue: () => ({
            fontSize: "0.75rem",
          }),
          placeholder: () => ({
            fontSize: "0.75rem",
          }),
          menu: (menuStyle) => ({
            ...menuStyle,
            zIndex: 1100,
            top: 40,
          }),
          menuList: (style: any) => ({
            ...style,
            fontSize: "0.75rem",
          }),
          control: () => ({
            display: "flex",
            flexDirection: "row",
            border: `1px solid ${error ? appTheme.colors.red : "rgba(29, 37, 59, 0.2)"}`,
            backgroundColor: "#fff",
            height: 44,
            color:
              // @ts-ignore
              (state && !!state[0]?.options?.length) || !!state?.value
                ? "#656565"
                : "#aaa",
            width: "100%",
            borderRadius: 4,
            "&:focus-within": {
              border: `1px solid ${error ? appTheme.colors.red : appTheme.colors.primary}`,
            },
            "&:hover": {
              border: `1px solid ${appTheme.colors.primary}`,
            },
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: "rgba(0,188,242,0.15)",
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            padding: "3px 8px",
            color: appTheme.colors.primary,
            fontWeight: 500,
            fontSize: "0.7rem",
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            color: "#FFF",
            backgroundColor: appTheme.colors.primary,
            cursor: "pointer",
            transition: "background-color 150ms ease-in-out",
            ":hover": {
              backgroundColor: shade(0.2, appTheme.colors.primary),
            },
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#dedede",
            primary: appTheme.colors.primary,
            neutral0: "#FFF",
            neutral50: "#bbb",
            neutral60: error ? appTheme.colors.red : appTheme.colors.primary,
            neutral80: "#656565",
          },
        })}
        onChange={handleChange}
        options={options}
        placeholder={label}
        value={state}
        noOptionsMessage={() => "Ops, nenhuma informação encontrada"}
      />
      {!!helperText && (
        <span
          style={{
            fontSize: "11px",
            color: error ? appTheme.colors.red : "#aaa",
            margin: "0 14px",
          }}
        >
          {error || helperText}
        </span>
      )}
    </Container>
  );
};

export default InputSelect;
