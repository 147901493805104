import Route from "../../services/route";

const rootPath = "/api/v1";

export const singinRouteApi = new Route('/auth');

export const usersRouteApi = new Route(`${rootPath}/users`);

export const questionsRouteApi = new Route(`${rootPath}/questions`);

export const responsesRouteApi = new Route(`${rootPath}/responses`);

export const analyticsRouteApi = new Route(`${rootPath}/analytics`);
