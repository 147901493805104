import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px 0;

  @media(max-width: 800px) {
    padding: 24px 16px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  padding: 16px;
  box-shadow: 0 1px 20px 0 ${(props) => props.theme.colors.boxShadow};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 32px;

    @media(max-width: 800px) {
      margin-right: 0;
    }
  }

  strong {
    margin-top: 16px;
    font-size: 0.9rem;
  }

  &:hover {
    box-shadow: 0 0px 4px 0 ${(props) => props.theme.colors.primary};
  }

  @media(max-width: 800px) {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    padding: 24px;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }
`;
