import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { toast } from "react-toastify";

import Routes from "./routes";
import GlobalStyle from "./styles/global";
import { getTheme } from "./styles/theme";
import { GlobalLoadingProvider } from "./hooks/GlobalLoadingContext";
import { AuthProvider } from "./hooks/AuthContext";

toast.configure();

const currentTheme = getTheme();

function App() {
  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={currentTheme}>
          <GlobalLoadingProvider>
            <AuthProvider>
              <GlobalStyle />
              <Routes />
            </AuthProvider>
          </GlobalLoadingProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
