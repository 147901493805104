import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    font-size: calc(10px + 1vmin);
  }

  body {
    font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Ubuntu';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility !important;
  }

  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${(props) => props.theme.colors.secundary};
    color: ${(props) => props.theme.colors.text};
  }

  .Toastify__toast--error {
    background: ${(props) => props.theme.colors.red};
    font-size: 0.8rem;
    border-radius: 3px;
  }

  .Toastify__toast--success {
    background: ${(props) => props.theme.colors.green};
    font-size: 0.8rem;
    border-radius: 3px;
  }

  .container {
    display: flex;
    width: 1170px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1200px) {
    .container {
      width: 1070px;
    }
  }

  @media (max-width: 1100px) {
    .container {
      width: 970px;
    }
  }

  @media (max-width: 1000px) {
    .container {
      width: 870px;
    }
  }

  @media (max-width: 900px) {
    .container {
      width: 770px;
    }
  }

  @media (max-width: 800px) {
    html {
      font-size: calc(15px + 1vmin);
    }

    .Toastify__toast--error {
      border-radius: 0;
    }

    .Toastify__toast--success {
      border-radius: 0;
    }

    .container {
      width: 100vw;
    }
  }
`;
