import styled, { css } from 'styled-components';

interface IContainer {
  speaking: 'true' | 'false';
}

const speakingCSS = css`
  button {
    transform: scale(1);
    animation: pulse 900ms infinite;

    @keyframes pulse {
      0% {
        transform: scale(0.94);
      }

      70% {
        transform: scale(1);
      }

      100% {
        transform: scale(0.94);
      }
    }
  }
`;

export const Container = styled.div<IContainer>`
  margin: 3px 0 10px 0;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    border: none;
    height: 40px;
    border-radius: 4px;
    background-color: ${
      props => props.speaking === 'true'
        ? props.theme.colors.red
        : props.theme.colors.green
    };
    color: ${props => props.theme.colors.white};
    font-weight: 600;
    cursor: pointer;

    span {
      font-size: 0.75rem;
      padding-left: 6px;
    }
  }

  ${props => props.speaking === 'true' && speakingCSS};

  @media(max-width: 600px) {
    button {
      width: 100%;
      max-width: none;
    }
  }
`;
