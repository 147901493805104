import React, { useState, useEffect, useCallback } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { BiMicrophone } from 'react-icons/bi';
import { TiMicrophone } from 'react-icons/ti';

import { Container } from './styles';

interface ISpeechToTextProps {
  onTranscript(text: string): void
}

const SpeechToText: React.FC<ISpeechToTextProps> = ({ onTranscript }) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const { transcript } = useSpeechRecognition();

  useEffect(
    () => {
      onTranscript(transcript)
    },
    [onTranscript, transcript]
  );

  useEffect(
    () => {
      async function verifyPermission() {
        const hasInternetConnection = navigator.onLine;
        const permission = await navigator.permissions?.query(
          { name: 'microphone' }
        );
        const browserSupportsSpeechRecognition = SpeechRecognition
          .browserSupportsSpeechRecognition();

        setHasPermission(
          permission?.state !== 'denied' &&
          hasInternetConnection &&
          browserSupportsSpeechRecognition
        );
      }

      verifyPermission();
    },
    []
  );

  const handleclick = useCallback(
    () => {
      setSpeaking(oldState => {
        if (oldState) {
          SpeechRecognition.stopListening();
        } else {
          SpeechRecognition.startListening({ language: 'pt-BR' });
        }

        return !oldState;
      });
    },
    []
  )

  if (!hasPermission) {
    return null;
  }

  return (
    <Container speaking={speaking ? 'true' : 'false'}>
      <button type="button" onClick={handleclick}>
        {speaking ? <TiMicrophone size={18} /> : <BiMicrophone size={18} />}
        <span>{speaking ? 'parar' : 'observação por audio'}</span>
      </button>
    </Container>
  )
}
export default SpeechToText;
