import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { Container } from './styles';

export interface ITextAreaProps {
  name: string;
  placeholder: string;
  type?: string;
  error?: string | undefined;
}

const TextArea: React.FC<ITextAreaProps> = ({ name, ...restProps }) => {
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Container hasError={!!error ? 'true' : 'false'}>
      <textarea
        ref={inputRef}
        name="observation"
        rows={5}
        cols={40}
        defaultValue={defaultValue}
        {...restProps}
      />
      {!!error && <div className="error">{error}</div>}
    </Container>
  );
}

export default TextArea;
