import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 56px;
  width: 100vw;
  padding: 0 24px;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 0px 5px 0px;

  img {
    width: 145px;
    cursor: pointer;
  }

  .profile {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        font-weight: 500;
      }

      svg {
        margin-left: 3px;
        color: ${props => props.theme.colors.text};
      }
    }
  }

  @media(max-width: 600px) {
    height: 54px;
    padding: 0 16px;

    .profile {
      a {
        div {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
`;
