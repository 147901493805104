import React from "react";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import Questions from "../../pages/Questions";

export const questionsRoute = new Route("/questions/:id");

export const QuestionsRouteComponent: React.FC = () => (
  <RouteWrapper
    exact
    isPrivate
    path={questionsRoute.path}
    component={Questions}
  />
);
