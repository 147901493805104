import React from "react";
import { Route, Redirect, RouteProps, useHistory } from "react-router-dom";

import { dashboardRoute, signInRoute } from "./config";
import { useAuth } from "../hooks/AuthContext";

interface RouteWrapperProps extends RouteProps {
  component: React.FC;
  isPrivate?: boolean;
  redirectIfNotExact?: boolean;
  path: string;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({
  component: Component,
  isPrivate = false,
  redirectIfNotExact = false,
  path,
  ...rest
}) => {
  const { location } = useHistory();
  const { signed } = useAuth();

  const loggedOutUserTryingAccessPrivateRoute = !signed && isPrivate;
  if (loggedOutUserTryingAccessPrivateRoute) {
    return <Redirect to={signInRoute.path} />;
  }

  const loggedUserTryingAccessPublicRoute = signed && !isPrivate;
  if (loggedUserTryingAccessPublicRoute) {
    return <Redirect to={dashboardRoute.path} />;
  }

  const invalidRoute = location.pathname !== path;
  const loggedUserTryingAccessUnmappedPrivateRoute =
    signed && isPrivate && invalidRoute;
  if (loggedUserTryingAccessUnmappedPrivateRoute && redirectIfNotExact) {
    return <Redirect to={path} />;
  }

  return (
    <Route {...rest} path={path}>
      <Component />
    </Route>
  );
};

export default RouteWrapper;
