import styled from "styled-components";

interface IInputProps {
  hasStartAdorment: 'true' | 'false';
  hasError: 'true' | 'false';
}

export const Container = styled.div<IInputProps>`
  margin-bottom: 10px;

  .content {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 44px;
    border: 1px solid ${
      props => props.hasError === 'true'
        ? props.theme.colors.red
        : 'rgba(29, 37, 59, 0.2)'
    };
    border-radius: 4px;
    padding: 1px;
    background-color: ${props => props.theme.colors.white};

    &:hover, &:focus-within {
      border: 1px solid ${props => props.theme.colors.primary};

      .start-adorment {
        svg {
          color: ${props => props.theme.colors.primary};
        }
      }
    }

    .start-adorment {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 40px;
      cursor: pointer;

      svg {
        color: ${
          props => props.hasError === 'true' ? props.theme.colors.red : '#aaa'
        };
      }
    }

    .input {
      height: 100%;
      width: 100%;
      padding: ${
        props => props.hasStartAdorment === 'true' ? '0 10px 0 0' : '0 10px'
      };
      border: none;
      color: #656565;
      caret-color: #656565;
      font-size: 0.79rem;

      ::-webkit-input-placeholder {
        color: #aaa;
      }

      :-ms-input-placeholder {
        color: #aaa;
      }

      ::placeholder {
        color: #aaa;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.white} inset;
        -webkit-text-fill-color: ${(props) => props.theme.colors.text};
        box-shadow: 0 0 0 30px ${(props) => props.theme.colors.white} inset;
        border-radius: 0;
      }

      @media (max-width: 800px) {
        font-size: 16px;
      }
    }
  }

  .error {
    font-size: 0.7rem;
    margin: 2px 0 0 8px;
    color: ${props => props.theme.colors.red}
  }
`;
