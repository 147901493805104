import styled from "styled-components";

export const Container = styled.div`
  .element {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    z-index: 9999;
  }

  .gradient-hide {
    background: transparent;
  }

  .gradient {
    background: repeating-linear-gradient(
      90deg,
      ${(props) => props.theme.colors.orange} 0,
      ${(props) => props.theme.colors.yellow} 15%,
      ${(props) => props.theme.colors.red} 20%,
      ${(props) => props.theme.colors.yellow} 35%,
      ${(props) => props.theme.colors.orange} 42%,
      ${(props) => props.theme.colors.red} 50%
    );
    background-size: 200% auto;
    background-position: 0 100%;
    -webkit-animation: placeHolderShimmer 2s infinite;
    animation: placeHolderShimmer 2s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -100vw 0;
    }
    100% {
      background-position: 100vw 0;
    }
  }
`;
