import React from "react";

import RouteWrapper from "../RouteWrapper";
import Dashboard from "../../pages/Dashboard";
import { dashboardRoute } from "./dashboard.route";

export const DefaultRouteComponent: React.FC = () => (
  <RouteWrapper
    exact
    isPrivate
    redirectIfNotExact
    path={dashboardRoute.path}
    component={Dashboard}
  />
);
