import React, { useRef, useCallback } from 'react';
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { useHistory, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useGlobalLoading } from "../../hooks/GlobalLoadingContext";
import { useRequest } from "../../hooks/RequestContext";
import { responsesRouteApi } from "../../routes/config/api";
import { dashboardRoute } from "../../routes/config";
import getValidationsErrors from "../../utils/errors/getValidationsErrors";

import Header from '../../components/Header';
import InputSelect from '../../components/Input/Select';
import InputTextArea from '../../components/Input/TextArea';
import Button from '../../components/Button';
import SpeechToText from '../../components/SpeechToText';

import { Container, Error } from './styles';

const lostSaleQuestion = [
  { label: 'Condição de pagamento', value: 'payment_terms', observation: false },
  { label: 'Crediário não aprovado', value: 'unapproved_credit', observation: false },
  { label: 'Demora na análise do crediário', value: 'credit_analysis_delay', observation: false },
  { label: 'Prazo de entrega', value: 'deadline', observation: false },
  { label: 'Produto não encontrado', value: 'product_not_found', observation: true },
  { label: 'Preço', value: 'price', observation: false },
  { label: 'Sem estoque disponível', value: 'no_stock_available', observation: false },
  { label: 'Não trabalhamos com a marca', value: 'dont_work_with_brand', observation: false },
  { label: 'Transferência para filiais', value: 'transfer_to_branches', observation: false },
];

const suggestionsQuestion = [
  { label: 'Sistema', value: 'system', observation: true },
  { label: 'Processos', value: 'processes', observation: true },
  { label: 'Infraestrutura', value: 'infrastructure', observation: true },
  { label: 'Comunicação', value: 'comunication', observation: true },
  { label: 'Outros', value: 'others', observation: true },
];

const LOST_SALE_QUESTION_ID = 1;

const Questions: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const params = useParams<any>();
  const { loading, setLoading } = useGlobalLoading();
  const request = useRequest();

  const createQuestionResponse = useCallback(
    async (data: any) => {
      try {
        await request.post(responsesRouteApi.path, {
          justification: data.justification.value,
          observation: data.observation,
          question_id: params.id,
        });

        toast.success("Resposta criada com sucesso");

        history.push(dashboardRoute.path);
      } catch(err) {
        toast.error("Ops, não foi possível salvar sua resposta!");
      }
    },
    [history, params.id, request]
  )

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const observationValidation = {};
        const selectedJustification = lostSaleQuestion.find(
          question => question.value === data.justification?.value
        );
        const selectedSuggestion = suggestionsQuestion.find(
          question => question.value === data.justification?.value
        );

        if (parseInt(params.id) === LOST_SALE_QUESTION_ID) {
          Object.assign(
            observationValidation,
            !!selectedJustification?.observation
              ? { observation : Yup.string().required("Campo obrigatório") }
              : {}
          )
        } else {
          Object.assign(
            observationValidation,
            !!selectedSuggestion?.observation
              ? { observation : Yup.string().required("Campo obrigatório") }
              : {}
          )
        }

        const schema = Yup.object().shape({
          justification: Yup.string().required("Campo obrigatório"),
          ...observationValidation,
        });

        await schema.validate(
          {
            justification: data.justification?.value,
            observation: data.observation?.trim(),
          },
          { abortEarly: false }
        );

        await createQuestionResponse(data);
      } catch (err) {
        const validationErrors = getValidationsErrors(err);
        formRef.current?.setErrors(validationErrors);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, params, createQuestionResponse]
  );

  const onTranscript = useCallback(
    (transcript: string) => {
      formRef.current?.setFieldValue("observation", transcript);
    },
    []
  )

  if (!['1', '2'].includes(params.id)) {
    return (
      <>
        <Header />
        <Error className="container">
          <span>Ops, pergunta não encontrada :(</span>
          <Link to={dashboardRoute.path}>Voltar para o início</Link>
        </Error>
      </>
    );
  }

  return (
    <>
      <Header />
      <Container className="container">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputSelect
            label="Informe o motivo"
            name="justification"
            isSearchable={false}
            options={
              (parseInt(params.id) === LOST_SALE_QUESTION_ID
                ? lostSaleQuestion
                : suggestionsQuestion
              ) as any
            }
          />
          <InputTextArea
            name="observation"
            placeholder={
              parseInt(params.id) === LOST_SALE_QUESTION_ID
                ? "Informe uma observação"
                : "Informe sua sugestão"
            }
          />
          <SpeechToText onTranscript={onTranscript} />

          <Button name="Salvar" disabled={loading} />
        </Form>
      </Container>
    </>
  );
}

export default Questions;
