import React, { useCallback } from 'react';
import { BsCardChecklist, BsArrowBarDown } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { questionsRoute } from '../../routes/config';
import { useAnalytics } from '../../hooks/AnalyticsContext';

import Header from '../../components/Header';

import { Container, ItemContainer } from './styles';

interface IItem {
  id: number;
  icon: React.FC;
  description: string;
  onClick(id:number, description: string): void;
}

const Item = ({ id, icon: Icon, description, onClick }: IItem) => (
  <ItemContainer onClick={() => onClick(id, description)}>
    <Icon />
    <strong>{description}</strong>
  </ItemContainer>
)

const Dashboard: React.FC = () => {
  const { dispatchAction } = useAnalytics();
  const history = useHistory();

  const handleClick = useCallback(
    (id: number, description: string) => {
      dispatchAction({ action: 'BUTTON_CLICK', description });
      history.push(questionsRoute.build({ id }));
    },
    [dispatchAction, history]
  );

  return (
    <>
      <Header />
      <Container className="container">
        <Item
          id={1}
          icon={() => <BsArrowBarDown size={65} />}
          description="Lançar venda perdida"
          onClick={handleClick}
        />

        <Item
          id={2}
          icon={() => <BsCardChecklist size={60} />}
          description="Sugestão de melhoria"
          onClick={handleClick}
        />
      </Container>
    </>
  );
}

export default Dashboard;
