import React from "react";
import MaskedInput from "react-text-mask";

import { Container } from "./styles";

export type Mask = Array<string | RegExp>;

export interface InputBaseProps {
  name: string;
  type?: string;
  inputRef?: any;
  inputMask?: Mask | ((value: string) => Mask);
  maskValue?: any;
  placeholder?: string;
  defaultValue?: any;
  error?: string | undefined;
  startAdorment?: React.FunctionComponent<any>;
  onChangeMask?: (e: any) => any;
  onClick?: () => void;
}

const anyCharacterRegEx = /./;
const defaultMask = Array(100).fill(anyCharacterRegEx);

const InputBase: React.FC<InputBaseProps> = ({
  inputRef,
  inputMask,
  maskValue,
  placeholder,
  error,
  onChangeMask,
  startAdorment: StartAdormentIcon = null,
  onClick,
  ...rest
}) => {
  return (
    <MaskedInput
      ref={inputRef}
      mask={inputMask || defaultMask}
      guide={false}
      value={maskValue}
      onChange={onChangeMask}
      render={(ref, inputMaskProps: any): any => {
        return (
          <Container
            hasError={!!error ? 'true' : 'false'}
            hasStartAdorment={!!StartAdormentIcon ? 'true' : 'false'}
          >
            <div className="content">
              {!!StartAdormentIcon && (
                <div className="start-adorment">
                  <StartAdormentIcon />
                </div>
              )}
              <input
                ref={ref}
                {...inputMaskProps}
                {...rest}
                className="input"
                placeholder={placeholder}
              />
            </div>
            {!!error && <div className="error">{error}</div>}
          </Container>
        );
      }}
    />
  );
};

export default InputBase;
