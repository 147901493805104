import React from 'react';

import { useAuth } from '../../hooks/AuthContext';

import Header from '../../components/Header';

import { Container, InputShow } from './styles';

interface IItemProps {
  label: string;
  value: string;
}

const Input = ({ label, value }: IItemProps) => (
  <InputShow>
    <strong>{label}</strong>
    <div>
      <span>{value}</span>
    </div>
  </InputShow>
)

const Profile: React.FC = () => {
  const { user, signOut } = useAuth();

  return (
    <>
      <Header />
      <Container className="container">
        <Input label="Empresa" value={user.branch?.company?.name} />
        <Input
          label="Filial"
          value={`${user.branch?.code} - ${user.branch?.name}`}
        />
        <Input label="Nome" value={user.name} />
        <Input label="Usuário" value={user.username} />

        <button onClick={signOut} className="logout">
          Sair
        </button>
      </Container>
    </>
  );
}

export default Profile;
