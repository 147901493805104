import React, { createContext, useContext, useCallback } from "react";
import { toast } from "react-toastify";
import { AxiosRequestConfig } from "axios";

import Request, { HTTP_STATUS } from "../services/request";
import { useAuth } from "../hooks/AuthContext";

export interface IRequestContextProps {
  get: typeof Request.get;
  post: typeof Request.post;
  put: typeof Request.put;
  del: typeof Request.del;
}

const RequestContext = createContext<IRequestContextProps>({} as IRequestContextProps);

export const RequestProvider: React.FC = ({ children }) => {
  const { signOut } = useAuth();

  const errorValidator = useCallback(
    (err: any) => {
      if (err?.response?.status === HTTP_STATUS.FORBIDDEN) {
        signOut();
        toast.error("Sua sessão encerrou. Faça login para continuar navegando!")
      } else {
        return Promise.reject(err);
      }
    },
    [signOut]
  );

  const get = useCallback(
    async function <T = any, R = T>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<R> {
      return Request
        .get(url, config)
        .then(response => response)
        .catch(errorValidator);
    },
    [errorValidator]
  );

  const post = useCallback(
    async function <T = any, R = T>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<R> {
      return Request
        .post(url, data, config)
        .then(response => response)
        .catch(errorValidator);
    },
    [errorValidator]
  );

  const put = useCallback(
    async function <T = any, R = T>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<R> {
      return Request
        .put(url, data, config)
        .then(response => response)
        .catch(errorValidator);
    },
    [errorValidator]
  );

  const del = useCallback(
    async function <T = any, R = T>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<R> {
      return Request
        .del(url, config)
        .then(response => response)
        .catch(errorValidator);
    },
    [errorValidator]
  );

  return (
    <RequestContext.Provider value={{ get, post, put, del }}>
      {children}
    </RequestContext.Provider>
  );
};

export const useRequest = (): IRequestContextProps => {
  const context = useContext(RequestContext);

  if (!context) {
    throw new Error("useRequest must be used within a RequestProvider");
  }

  return context;
};

export default RequestContext;
