import styled from 'styled-components';

interface IContiner {
  hasError: 'true' | 'false';
}

export const Container = styled.div<IContiner>`
  width: 100%;

  textarea {
    width: 100%;
    resize: none;
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid ${
      props => props.hasError === 'true'
        ? props.theme.colors.red
        : 'rgba(29, 37, 59, 0.2)'
    };
    font-family: Roboto;
    font-size: 0.75rem;
    color: #656565;

    &::-webkit-input-placeholder {
      color: #aaa;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: #aaa;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      color: #aaa;
    }

    &:-ms-input-placeholder {
      color: #aaa;
    }

    &::placeholder {
      color: #aaa;
    }

    &:hover, :focus-within {
      border: 1px solid ${ props => props.theme.colors.primary};
    }

    @media(max-width: 600px) {
      font-size: 16px;
    }
  }

  .error {
    font-size: 0.7rem;
    margin-left: 8px;
    color: ${props => props.theme.colors.red}
  }
`;
